<template>
  <div class="post-edit-container timeline" :class="{
    '--theme-group': renderType === 'group',
  }">
    <div class="page__title">
      <svg-icon icon-class="icon-plus-red" :color="renderType === 'group' ? '#027711' : '#C81D25'"></svg-icon>
      <p>
        <strong>{{ formTitle }}</strong>
      </p>
    </div>
    <!-- form框 -->
    <div class="edit-form-container">
      <van-form @submit="onSubmit" class="post-edit-form">
        <div class="form__item">
          <van-field v-model="form.content" class="textarea" type="textarea" maxlength="500" :placeholder="placeholder"
            show-word-limit :rules="formRules">
            <template #label>
              <p><span>1、</span>编辑内容</p>
            </template>
          </van-field>
        </div>
        <div class="form__item">
          <van-field>
            <template #input>
              <wx-upload prefix="timeline-post" :limit="9" @onChange="onUploadChange"></wx-upload>
            </template>
          </van-field>
        </div>
        <div class="form__item">
          <van-field v-model="form.publishAt" type="hidden" readonly clickable @click="isShowDatePicker = true" :rules="[
            {
              required: true,
              message: '请选择日期',
            },
          ]">
            <template #label>
              <p><span class="font-red">2、</span>选择日期</p>
            </template>
            <template #extra>
              <div class="item__right-container">
                <p v-if="form.publishAt">{{ form.publishAt | parseTime("{y}-{m}-{d}") }}</p>
                <p class="date-placeholder" v-else>
                  <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
                </p>
              </div>
            </template>
          </van-field>
        </div>
        <div class="form__item" @click="onShowFriendChoose" v-if="renderType !== 'group'">
          <van-field v-model="form.relatedUids" type="hidden" readonly clickable>
            <template #label>
              <p>
                <span class="font-red">3、</span>{{ renderType === "group" ? "选择小组" : "关联亲友" }}
              </p>
            </template>
            <template #extra>
              <div class="item__right-container with-bt">
                <p>{{ relatedCounts }}</p>
                <svg-icon icon-class="icon-right"></svg-icon>
              </div>
            </template>
          </van-field>
        </div>
        <!-- 底部导航 -->
        <div class="bottom-menu-container">
          <van-button class="form-cancel-bt" native-type="button" @click.native="goBack">取消</van-button>
          <van-button class="form-submit-bt" :loading="requesting" loading-text="提交中..."><strong>确定</strong></van-button>
        </div>
      </van-form>
    </div>
    <!-- 时间选择 -->
    <van-popup v-model="isShowDatePicker" position="bottom">
      <van-datetime-picker type="date" @confirm="onDatePickerConfirm" @cancel="isShowDatePicker = false"
        v-model="currentDate" :min-date="minDate" :max-date="maxDate"></van-datetime-picker>
    </van-popup>
    <!-- 好友选择 -->
    <transition name="slide">
      <div class="common-search-list-container posts-friend-select" v-if="isShowFriendSelect">
        <member-search class="post-friend-select-wrapper" @search="onFriendSearch" @cancel="onFriendCancel"
          @submit="onFriendSubmit" type="timeline-select" :lists="friendLists" :max-limit="selectLimit"
          :selected="form.relatedUids"></member-search>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import wxUpload from '../common/_upload-bt.vue';
import memberSearch from '../common/_member-search.vue';

export default {
  name: 'page-timeline-edit',
  components: {
    wxUpload,
    memberSearch,
  },
  data() {
    const next50 = new Date().getFullYear() + 50;
    return {
      isShowDatePicker: false,
      isShowFriendSelect: false,
      form: {
        content: '',
        imgs: '',
        publishAt: '',
        relatedUids: '',
      },
      minDate: new Date('1960/01/01'),
      maxDate: new Date(`${next50}/12/31`),
      currentDate: new Date(),
      selectLimit: 9,
      friendLists: null,
      requesting: false,
      test: false,
      formRules: [
        {
          required: true,
          message: '请填写内容',
        },
      ],
    };
  },
  computed: {
    ...mapState(['friends', 'forward']),
    relatedCounts() {
      const arr = this.form.relatedUids ? this.form.relatedUids.split(',') : [];
      return arr.length;
    },
    renderType() {
      return this.groupID > 0 ? 'group' : 'personal';
    },
    groupID() {
      return Number(this.$route.query.groupID) || 0;
    },
    formTitle() {
      return this.renderType === 'group' ? '珍藏小组时光' : '珍藏时光';
    },
    placeholder() {
      return this.renderType === 'group' ? '珍藏小组时光…（组内成员可见）' : '珍藏美好时光…';
    },
  },
  watch: {
    friends(val) {
      this.friendLists = val;
    },
  },
  mounted() {
    this.friendLists = this.friends;
    if (this.$route.query.friend) {
      this.form.relatedUids = this.$route.query.friend;
    }
  },
  methods: {
    onUploadChange(val) {
      this.form.imgs = val.length > 0 ? val : '';
    },
    onSubmit() {
      const that = this;
      const data = {
        content: that.form.content,
        imgs: that.form.imgs,
        publishAt: that.form.publishAt,
        relatedUids: that.form.relatedUids,
      };
      data.relatedUids = data.relatedUids === '' ? null : data.relatedUids.split(',');
      if (!that.requesting) {
        that.requesting = true;
        const postURI = that.renderType === 'group'
          ? `api/group/${that.groupID}/timeline/post`
          : 'api/user/timeline/post';
        that.$request
          .post(postURI, data)
          .then((res) => {
            // 跳转到timelin详情
            const { post } = res.data;
            let link = '';
            if (that.groupID > 0) {
              const groupLink = `/group/${that.groupID}/timeline/${post.id}`;
              link = that.forward ? that.forward : groupLink;
            } else {
              link = data.relatedUids ? `/friend/${data.relatedUids['0']}/timeline` : `/timeline/${post.id}`;
            }
            that.$router.replace({
              path: link,
            });
            // if (that.forward) {
            //   that.$router.replace({
            //     path: that.forward,
            //   });
            // } else {
            //   const link = that.groupID > 0
            //     ? `/group/${that.groupID}/timeline/${post.id}`
            //     : afterPostTimelineJump;
            //   that.$router.replace({
            //     path: link,
            //   });
            // }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDatePickerConfirm(date) {
      this.form.publishAt = new Date(date).getTime() / 1000;
      this.isShowDatePicker = false;
    },
    onShowFriendChoose() {
      this.isShowFriendSelect = true;
    },
    onCloseFriendChoose() {
      this.isShowFriendSelect = false;
    },
    onFriendSearch(keyword) {
      if (keyword) {
        this.friendLists = this.friends.filter((item) => {
          const nickname = item.info.nickname.match(keyword);
          const comment = item.comment.match(keyword);
          return !(nickname === null && comment === null);
        });
      } else {
        this.friendLists = this.friends;
      }
    },
    onFriendCancel() {
      this.onCloseFriendChoose();
    },
    onFriendSubmit(uids) {
      this.form.relatedUids = uids.join(',');
      this.onCloseFriendChoose();
    },
  },
};
</script>
